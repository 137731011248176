import React from 'react';

const Global: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33325 19.9999C2.33325 10.2429 10.2429 2.33325 19.9999 2.33325C29.7569 2.33325 37.6666 10.2429 37.6666 19.9999C37.6666 29.7569 29.7569 37.6666 19.9999 37.6666C10.2429 37.6666 2.33325 29.7569 2.33325 19.9999ZM19.9999 4.33325C11.3475 4.33325 4.33325 11.3475 4.33325 19.9999C4.33325 28.6523 11.3475 35.6666 19.9999 35.6666C28.6523 35.6666 35.6666 28.6523 35.6666 19.9999C35.6666 11.3475 28.6523 4.33325 19.9999 4.33325Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33325 19.9999C2.33325 19.4476 2.78097 18.9999 3.33325 18.9999H36.6666C37.2189 18.9999 37.6666 19.4476 37.6666 19.9999C37.6666 20.5522 37.2189 20.9999 36.6666 20.9999H3.33325C2.78097 20.9999 2.33325 20.5522 2.33325 19.9999Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9999 2.33325C20.2808 2.33325 20.5488 2.45141 20.7383 2.65883C25.0706 7.40172 27.5327 13.5568 27.6664 19.9791C27.6667 19.993 27.6667 20.0069 27.6664 20.0207C27.5327 26.4431 25.0706 32.598 20.7383 37.341C20.5488 37.5484 20.2808 37.6666 19.9999 37.6666C19.719 37.6666 19.451 37.5484 19.2616 37.341C14.9293 32.598 12.4673 26.4431 12.3335 20.0207C12.3332 20.0069 12.3332 19.993 12.3335 19.9791C12.4673 13.5568 14.9293 7.40172 19.2616 2.65883C19.451 2.45141 19.719 2.33325 19.9999 2.33325ZM19.9999 4.85415C16.4584 9.10733 14.4539 14.4449 14.3335 19.9999C14.4539 25.5549 16.4583 30.8924 19.9999 35.1457C23.5415 30.8924 25.546 25.5549 25.6664 19.9999C25.546 14.4449 23.5415 9.10733 19.9999 4.85415Z"
      />
    </svg>
  );
};

export default Global;
